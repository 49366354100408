import React from "react"
import { Link } from "gatsby"
export default function PostTease({ post }) {
  const title = post.frontmatter.title || post.fields.slug
  return (
    <div className="mb-12">
      <article
        className="post-list-item"
        itemScope
        itemType="http://schema.org/Article"
      >
        <header>
          <h2 className="font-bold text-2xl mb-4">
            <Link to={post.fields.slug} itemProp="url">
              <span itemProp="headline">{title}</span>
            </Link>
          </h2>
          <p className="mb-4 text-sm">{post.frontmatter.date}</p>
        </header>
        <section>
          <p
            className="text-lg"
            dangerouslySetInnerHTML={{
              __html: post.frontmatter.description || post.excerpt,
            }}
            itemProp="description"
          />
        </section>
      </article>
    </div>
  )
}
